import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'

import { SimulationStepsProps } from './simulation-steps.model'
import * as S from './styles'

export const SimulationSteps: FC<SimulationStepsProps> = memo(({ 
  medium = false, 
  children, 
  cards, 
  onClick, 
  textCTA = 'Simular agora' 
}) => {
  const { isTablet } = useWindowSize()

  return (
    <S.Section bg={medium ? 'medium' : 'default'}>
      <Grid col={{ phone: 1, tablet: 12 }} gap={{ phone: 'xxs' }}>
        <Col col={{ tablet: 5, desktop: 5 }} start={{ desktop: 2 }}>
          <S.Infos>{children}</S.Infos>
        </Col>
        <Col col={{ tablet: 6, desktop: 6 }} start={{ tablet: 7, desktop: 7 }}>
          {cards.map(({ id, title, description }) => (
            <S.ListItem key={id}>
              <S.Counter>
                {id}
              </S.Counter>
              <div>
                <Text as="h3" size="heading5" weight="medium" color="var(--colors-neutral-text-default)">
                  {title}
                </Text>
                <Text size="body1" color="var(--colors-neutral-text-weak)">
                  {description}
                </Text>
              </div>
            </S.ListItem>
          ))}
          {!isTablet && onClick && (
            <S.ButtonContainer>
              <Button size="lg" variants="text-only" onClick={onClick}>
                {textCTA}
              </Button>
            </S.ButtonContainer>
          )}
        </Col>
      </Grid>
    </S.Section>
  )
})

SimulationSteps.displayName = 'SimulationSteps'
