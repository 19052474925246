import styled from 'styled-components'

import { Section as SectionGS, Infos as InfosGS } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, colors, space, radii, fontSizes, fontWeights } = theme

export const Section = styled(SectionGS)`
  padding-bottom: 0;

  @media (${media.tablet}) {
    padding-bottom: ${space['spacing-xxl']};
  }

  @media (${media.desktop}) {
    padding-bottom: 7.5rem;
  }
`

export const Infos = styled(InfosGS)`
  @media (${media.tablet}) {
    max-width: 31rem;
  }

  @media (${media.desktop}) {
    min-height: 27.188rem;
  }
`

export const Counter = styled.span`
  border: 2px solid ${colors['pink-icon-hover']};
  border-radius: ${radii['corner-radius-circular']};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space['spacing-xxl']};
  height: ${space['spacing-xxl']};
  font-size: ${fontSizes['font-size-body-1']};
  font-weight: ${fontWeights['font-weight-bold']};
`

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 50px minmax(0, 350px);
  gap: ${space['spacing-md']};
  margin-bottom: ${space['spacing-xl']};

  h5 {
    margin-bottom: ${space['spacing-xs']};
  }
`
export const ButtonContainer = styled.div`
  margin-bottom: ${space['spacing-xl']};

  button{
    display: block;
    width: 100%;
  }
`
