export const cards = [
  {
    id: '1',
    title: 'Escolha do plano',
    description: 'Primeiro, selecione um plano com valor, prazo e parcelas que se adequem à sua realidade financeira.'
  },
  {
    id: '2',
    title: 'Pagamento',
    description:
      'Efetue o pagamento à administradora mensalmente e acompanhe os sorteios. Você também pode oferecer lances para aumentar suas chances de contemplação. Assim como em um leilão, você só paga pelo lance se a sua oferta for a vencedora.'
  },
  {
    id: '3',
    title: 'Contemplação',
    description:
      'Quando for contemplado por sorteio ou lance, você terá direito a utilizar a carta de crédito contratada, seguindo as regras estabelecidas pela administradora. Ao final de um grupo, todos os consorciados ativos terão sido contemplados.'
  }
]
