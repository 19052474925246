import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'

import useWindowSize from 'hooks/useWindowSize'
import { SimulationSteps } from 'src/presentation/modules/simulation-steps'

import { ConsortiumStepsProps } from './consortium-steps.model'
import { cards } from './data'

const ConsortiumSteps: FC<ConsortiumStepsProps> = memo(({ onClick }) => {
  const { isTablet } = useWindowSize()

  return (
    <SimulationSteps cards={cards} onClick={onClick} textCTA="Saiba mais">
      <Text as="h2" size="heading3" weight="medium">
        <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
          Como participar{' '}
        </Text>
        de um consórcio?
      </Text>
      <Text className="description" color="var(--colors-neutral-text-weak)">
        Veja na prática como o consórcio funciona na CashMe:
      </Text>
      {isTablet && (
        <Button size="lg" onClick={onClick} variants="default" iconName="arrowRight" css={{ maxWidth: 'max-content' }}>
          Saiba mais
        </Button>
      )}
    </SimulationSteps>
  )
})

ConsortiumSteps.displayName = 'ConsortiumSteps'

export default ConsortiumSteps
